<!-- (c) 智汇考题 -->
<template>
  <div class="container">
    <nav-header></nav-header>
    <template v-if="!loading">
      <div class="group-form-box">
        <div class="group-title">重置密码</div>
        <div class="group-item">
          <div class="mobile">
            <span class="tit">手机号</span>
            {{ localUser.mobile }}
          </div>
        </div>
        <div class="group-item">
          <div class="name">新密码</div>
          <div class="value">
            <input
              class="input-text"
              type="password"
              v-model="form.password"
              placeholder="请输入新密码"
            />
            <img
              v-show="form.password"
              src="../../assets/img/new/close.png"
              style="width:16px;height:16px;"
              @click="clearPassword()"
            />
          </div>
        </div>
      </div>

      <div class="box pl-60 pr-60">
        <div
          class="btn-confirm"
          :class="{ active: form.password}"
          @click="submit"
        >
          重置密码
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import NavHeader from "../../components/nav-header.vue";

export default {
  components:{
    NavHeader
  },
  data() {
    return {
      loading: true,
      confirmDialog: false,
      captcha: {
        img: null,
        key: null,
      },
      form: {
        mobile: "",
        captcha: "",
        password: "",
      },
      openmask: false,
      reCaptcha: false,
      localUser: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api.User.Detail().then((res) => {
        this.loading = false;
        this.localUser = res.data;
      });
    },
    clearPassword() {
      this.form.password = null;
    },
    openDialog() {
      if (this.sms.loading) {
        // 冷却中
        return;
      }
      if (!this.$utils.isChinaMobilePhone(this.localUser.mobile)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      this.form.captcha = null;
      this.openmask = true;
    },
    qx() {
      this.openmask = false;
    },
    submit(val) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.form.sms = val;
      if (!this.form.password) {
        this.$message.error("请输入密码");
        return;
      }
      this.$api.Auth.PasswordForget({
        mobile: this.localUser.mobile,
        password: this.form.password,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("修改成功");
          setTimeout(() => {
            this.$router.back();
          }, 500);
        })
        .catch((e) => {
          this.loading = false;
          this.$message.error(e.message);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.btn-confirm {
  width: 100%;
  height: 48px;
  background-color: rgba(#3ca7fa, 0.6);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  &.active {
    background: #3ca7fa;
  }
}

.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  float: left;
  background: white;

  .group-form-box {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 0px;
    background-color: white;
    box-sizing: border-box;
    padding: 30px 20px 0 20px;
    .group-title {
      width: 100%;
      height: 24px;
      font-size: 24px;
      font-weight: 500;
      color: #171923;
      line-height: 24px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }
    .group-item {
      width: 100%;
      height: auto;
      float: left;
      box-sizing: border-box;
      padding: 6px 0;
      display: flex;
      border-bottom: 1px solid #f4faff;
      margin-bottom: 50px;
      .mobile {
        width: auto;
        height: auto;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        .tit {
          margin-right: 25px;
        }
      }

      .name {
        width: auto;
        height: auto;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-right: 25px;
      }

      .value {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;
        .input-text {
          max-width: 115px;
          height: 36px;
          float: left;
          box-sizing: border-box;
          outline: none;
          color: #333333;
          font-size: 16px;
          border: none;
        }
      }
    }
  }
}
.captcha-img{
    width: 90px;
    height: 35px;
}
</style>
